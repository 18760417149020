@charset "UTF-8";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

ul {
  list-style-type: none; }

a {
  color: inherit;
  text-decoration: none; }

ul,
ol,
div {
  margin: 0;
  padding: 0; }

html {
  font-family: noto-sans-cjk-jp, sans-serif;
  color: #333;
  font-size: 10px;
  -webkit-font-feature-settings: 'palt';
          font-feature-settings: 'palt';
  scroll-behavior: smooth; }
  @media screen and (max-width: 980px) {
    html {
      min-width: 980px; } }
  @media screen and (max-width: 767px) {
    html {
      min-width: 100%; } }

.overflow {
  overflow: hidden; }

.mobile-hide {
  display: block; }
  @media screen and (max-width: 767px) {
    .mobile-hide {
      display: none; } }

.mobile-show {
  display: none; }
  @media screen and (max-width: 767px) {
    .mobile-show {
      display: block; } }

.header {
  padding: 25px 20px 25px 30px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  background: #fff; }
  @media screen and (max-width: 1366px) {
    .header {
      padding: 12px 2%; } }
  @media screen and (max-width: 767px) {
    .header {
      padding: 12px 10px 12px 15px; } }
  .header__content {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  @media screen and (max-width: 1024px) {
    .header__logo {
      position: relative;
      z-index: 6; } }
  @media screen and (max-width: 767px) {
    .header__logo {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; } }
  @media screen and (max-width: 767px) {
    .header__logo img,
    .header__logo svg {
      width: 30%;
      height: auto; } }
  .header__logo p {
    font-size: 1.5rem;
    letter-spacing: 0.03em; }
    @media screen and (max-width: 767px) {
      .header__logo p {
        font-size: 1.2rem;
        margin-left: 15px; } }
  .header__nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1.8rem;
    letter-spacing: 0;
    font-weight: 400; }
    @media screen and (max-width: 1250px) {
      .header__nav {
        font-size: 1.4rem; } }
    @media screen and (max-width: 1024px) {
      .header__nav {
        position: fixed;
        top: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.9);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 55px;
        padding-top: 30px;
        font-size: 46px;
        font-weight: 500;
        font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
        overflow: hidden;
        width: 100%;
        max-width: 0;
        -webkit-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease; } }
    @media screen and (max-width: 767px) {
      .header__nav {
        font-size: 23px; } }
    @media screen and (max-width: 1024px) {
      .header__nav * {
        white-space: nowrap; } }
  .header__link {
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease; }
    @media screen and (max-width: 1024px) {
      .header__link {
        color: #d6258b;
        position: relative; } }
    .header__link::before {
      content: '';
      position: absolute;
      left: -35px;
      top: 50%;
      width: 20px;
      height: 20px;
      border-right: 4px solid #d6258b;
      border-bottom: 4px solid #d6258b;
      -webkit-transform: translateY(-50%) rotate(-45deg);
          -ms-transform: translateY(-50%) rotate(-45deg);
              transform: translateY(-50%) rotate(-45deg);
      display: none; }
      @media screen and (max-width: 1024px) {
        .header__link::before {
          display: block; } }
      @media screen and (max-width: 767px) {
        .header__link::before {
          left: -20px;
          width: 10px;
          height: 10px;
          border-right: 2px solid #d6258b;
          border-bottom: 2px solid #d6258b; } }
    .header__link:hover {
      color: #d6258b; }
    .header__link:not(:last-of-type) {
      margin-right: 35px; }
      @media screen and (max-width: 1366px) {
        .header__link:not(:last-of-type) {
          margin-right: 2vw; } }
      @media screen and (max-width: 1024px) {
        .header__link:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: 20px; } }
    .header__link.contact {
      width: 170px;
      height: 50px;
      border-radius: 5px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      color: #fff;
      background-color: #d6258b;
      -webkit-transition: all 400ms ease;
      -o-transition: all 400ms ease;
      transition: all 400ms ease; }
      @media screen and (max-width: 1366px) {
        .header__link.contact {
          width: 10vw; } }
      @media screen and (max-width: 1024px) {
        .header__link.contact {
          width: 64%;
          max-width: 480px;
          height: 90px;
          background: -webkit-gradient(linear, left bottom, left top, color-stop(5%, #9c1f67), to(#d6258b));
          background: -o-linear-gradient(bottom, #9c1f67 5%, #d6258b 100%);
          background: linear-gradient(0deg, #9c1f67 5%, #d6258b 100%); } }
      @media screen and (max-width: 767px) {
        .header__link.contact {
          max-width: 250px;
          overflow: visible;
          height: 45px;
          font-size: 23px; } }
      .header__link.contact:hover {
        opacity: 0.7;
        color: #fff; }
        @media screen and (max-width: 1024px) {
          .header__link.contact:hover {
            opacity: 1; } }
      @media screen and (max-width: 1024px) {
        .header__link.contact::before {
          content: none; } }
      .header__link.contact p {
        pointer-events: none; }
        @media screen and (max-width: 1024px) {
          .header__link.contact p {
            position: relative;
            display: inline-block; } }
        .header__link.contact p::before {
          content: '';
          position: absolute;
          left: -35px;
          top: 50%;
          width: 20px;
          height: 20px;
          border-right: 4px solid #fff;
          border-bottom: 4px solid #fff;
          -webkit-transform: translateY(-50%) rotate(-45deg);
              -ms-transform: translateY(-50%) rotate(-45deg);
                  transform: translateY(-50%) rotate(-45deg);
          display: none; }
          @media screen and (max-width: 1024px) {
            .header__link.contact p::before {
              display: block; } }
          @media screen and (max-width: 767px) {
            .header__link.contact p::before {
              width: 10px;
              height: 10px;
              left: -20px;
              border-right: 2px solid #fff;
              border-bottom: 2px solid #fff; } }
  .header__toggle {
    display: none; }
    @media screen and (max-width: 1024px) {
      .header__toggle {
        display: block;
        position: relative;
        z-index: 6; } }
  .header__toggle-lines {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
    .header__toggle-lines span {
      width: 60px;
      height: 4px;
      background-color: #d6258b;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease; }
      @media screen and (max-width: 767px) {
        .header__toggle-lines span {
          width: 30px;
          height: 3px; } }
      .header__toggle-lines span:not(:last-of-type) {
        margin-bottom: 15px; }
        @media screen and (max-width: 767px) {
          .header__toggle-lines span:not(:last-of-type) {
            margin-bottom: 5px; } }
  .header.toggled .header__nav {
    max-width: 100%;
    overflow: auto;
    padding-bottom: 50px; }
  .header.toggled .header__toggle-lines {
    height: 20px; }
    .header.toggled .header__toggle-lines span {
      margin-bottom: 0; }
      .header.toggled .header__toggle-lines span:first-of-type {
        -webkit-transform: rotate(-45deg) translateY(8px) translateX(-8px);
            -ms-transform: rotate(-45deg) translateY(8px) translateX(-8px);
                transform: rotate(-45deg) translateY(8px) translateX(-8px); }
        @media screen and (max-width: 767px) {
          .header.toggled .header__toggle-lines span:first-of-type {
            -webkit-transform: rotate(-45deg) translateY(7px) translateX(-6px);
                -ms-transform: rotate(-45deg) translateY(7px) translateX(-6px);
                    transform: rotate(-45deg) translateY(7px) translateX(-6px); } }
      .header.toggled .header__toggle-lines span:nth-of-type(2) {
        opacity: 0; }
      .header.toggled .header__toggle-lines span:last-of-type {
        -webkit-transform: rotate(45deg) translateX(4px) translateY(-1px);
            -ms-transform: rotate(45deg) translateX(4px) translateY(-1px);
                transform: rotate(45deg) translateX(4px) translateY(-1px); }
        @media screen and (max-width: 767px) {
          .header.toggled .header__toggle-lines span:last-of-type {
            -webkit-transform: rotate(45deg) translateX(2px) translateY(1px);
                -ms-transform: rotate(45deg) translateX(2px) translateY(1px);
                    transform: rotate(45deg) translateX(2px) translateY(1px); } }

.footer {
  padding: 25px 0 30px;
  text-align: center; }
  .footer__text {
    font-size: 1.6rem;
    line-height: 1.75em; }
    @media screen and (max-width: 767px) {
      .footer__text {
        font-size: 12px; } }

.page-top {
  position: fixed;
  bottom: 0;
  right: 5px;
  width: 100px;
  height: 60px;
  background-color: #2c333d;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform: translateY(110%);
      -ms-transform: translateY(110%);
          transform: translateY(110%); }
  @media screen and (max-width: 767px) {
    .page-top {
      width: 50px;
      height: 30px;
      right: 0; } }
  .page-top:hover {
    opacity: 0.7; }
  .page-top.scrolled {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0); }
  .page-top__arrow {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 15px;
    height: 15px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
        -ms-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg); }
    @media screen and (max-width: 767px) {
      .page-top__arrow {
        top: 70%;
        width: 20px;
        height: 20px; } }

.page-title {
  text-align: center; }
  .page-title--jp {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    line-height: 1.8em;
    color: #333;
    position: relative;
    margin-bottom: 5px;
    padding-bottom: 5px; }
    @media screen and (max-width: 767px) {
      .page-title--jp {
        font-size: 24px;
        line-height: 1.125em;
        padding-bottom: 10px; } }
    .page-title--jp::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 30px;
      height: 2px;
      background-color: #d6258b; }
  .page-title--en {
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.75em;
    color: #d6258b; }
    @media screen and (max-width: 767px) {
      .page-title--en {
        font-size: 15px; } }

.main-visual {
  width: 100%;
  height: 100vh;
  max-height: 745px;
  margin-top: 121px;
  padding-top: 60px;
  padding-left: 5%;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 1024px) {
    .main-visual {
      max-height: 1200px;
      min-height: 1200px;
      padding-top: 0;
      padding-left: 5%; } }
  @media screen and (max-width: 767px) {
    .main-visual {
      height: 200vh;
      max-height: 560px;
      min-height: 560px;
      margin-top: 56px;
      padding-top: 0;
      padding-left: 0; } }
  .main-visual__text {
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    font-weight: 500;
    color: #950f5c;
    position: relative;
    z-index: 4;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; }
    @media screen and (max-width: 1024px) {
      .main-visual__text {
        padding: 0 5%;
        position: absolute;
        bottom: 5%;
        left: 0; } }
    @media screen and (max-width: 767px) {
      .main-visual__text {
        bottom: 9%; } }
    .main-visual__text.white {
      color: #fff;
      text-shadow: 0 0 40px #000, 0 0 10px #000, 0 0 20px #000, 0 0 50px #000; }
      @media screen and (max-width: 1024px) {
        .main-visual__text.white {
          color: #950f5c;
          text-shadow: none; } }
    .main-visual__text--jp {
      font-size: 2.34261vw;
      letter-spacing: 0.05em;
      line-height: 1.625em; }
      @media screen and (max-width: 1024px) {
        .main-visual__text--jp {
          font-size: 40px; } }
      @media screen and (max-width: 767px) {
        .main-visual__text--jp {
          font-size: 20px;
          line-height: 1.425em;
          letter-spacing: -0.05em;
          margin-bottom: 0; } }
      @media screen and (max-width: 320px) {
        .main-visual__text--jp {
          font-size: 18px; } }
    .main-visual__text--en {
      font-size: 1.1713vw;
      color: #d6258b;
      line-height: 1.5em; }
      @media screen and (max-width: 1024px) {
        .main-visual__text--en {
          font-size: 26px;
          width: 80%;
          position: absolute;
          bottom: 15%; } }
      @media screen and (max-width: 767px) {
        .main-visual__text--en {
          bottom: 10%;
          font-size: 13px;
          line-height: 1.53846em; } }
    .main-visual__text span {
      color: #fff; }
      @media screen and (max-width: 1024px) {
        .main-visual__text span {
          color: #d6258b; } }
  .main-visual__bg {
    max-height: 700px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 1.3s ease;
    -o-transition: all 1.3s ease;
    transition: all 1.3s ease;
    opacity: 0; }
    @media screen and (max-width: 1024px) {
      .main-visual__bg {
        max-height: 840px; } }
    @media screen and (max-width: 767px) {
      .main-visual__bg {
        max-height: 420px; } }
    .main-visual__bg.second {
      text-align: right;
      overflow: hidden; }
      @media screen and (max-width: 1024px) {
        .main-visual__bg.second {
          overflow: initial; } }
      .main-visual__bg.second::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 54%;
        height: 100%;
        background: -webkit-gradient(linear, left top, right top, color-stop(60%, white), to(rgba(255, 255, 255, 0)));
        background: -o-linear-gradient(left, white 60%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(90deg, white 60%, rgba(255, 255, 255, 0) 100%);
        display: none; }
        @media screen and (max-width: 1024px) {
          .main-visual__bg.second::before {
            display: block;
            height: 30%;
            top: auto;
            bottom: -1%;
            width: 100%;
            background: -webkit-gradient(linear, left bottom, left top, color-stop(30%, white), to(rgba(255, 255, 255, 0)));
            background: -o-linear-gradient(bottom, white 30%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(0deg, white 30%, rgba(255, 255, 255, 0) 100%);
            z-index: 1; } }
        @media screen and (max-width: 767px) {
          .main-visual__bg.second::before {
            background: -webkit-gradient(linear, left bottom, left top, color-stop(10%, white), to(rgba(255, 255, 255, 0)));
            background: -o-linear-gradient(bottom, white 10%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(0deg, white 10%, rgba(255, 255, 255, 0) 100%);
            height: 15%; } }
      .main-visual__bg.second img {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 0;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%); }
        @media screen and (max-width: 1440px) {
          .main-visual__bg.second img {
            left: -3%;
            -webkit-transform: translate(0);
                -ms-transform: translate(0);
                    transform: translate(0);
            width: 1580px; } }
        @media screen and (max-width: 1024px) {
          .main-visual__bg.second img {
            height: 100%;
            width: auto;
            margin-right: -6%;
            position: static; } }
        @media screen and (max-width: 767px) {
          .main-visual__bg.second img {
            height: 100%;
            width: auto;
            margin-right: 0;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                    transform: translateX(-50%); } }
    .main-visual__bg.active {
      z-index: 3;
      opacity: 1; }
  .main-visual .circ {
    border-radius: 50%;
    position: absolute; }
  .main-visual .gray1 {
    background-color: #e9eef3;
    width: 23.42606vw;
    height: 23.42606vw;
    left: -100px; }
    @media screen and (max-width: 1024px) {
      .main-visual .gray1 {
        width: 320px;
        height: 320px;
        top: 10%; } }
    @media screen and (max-width: 767px) {
      .main-visual .gray1 {
        width: 160px;
        height: 160px;
        top: 15%;
        left: -10%; } }
  .main-visual .gray2 {
    width: 14vw;
    height: 14vw;
    background-color: #e9eef3;
    bottom: 10%;
    left: 15%; }
    @media screen and (max-width: 1024px) {
      .main-visual .gray2 {
        z-index: 1; } }
    @media screen and (max-width: 767px) {
      .main-visual .gray2 {
        width: 52.5px;
        height: 52.5px;
        background-color: #e9eef3;
        bottom: 10%;
        left: 15%; } }
  .main-visual .gray3 {
    width: 6.66667vw;
    height: 6.66667vw;
    border: 2px solid #e9eef3;
    background-color: transparent;
    bottom: 6%;
    left: 25%; }
    @media screen and (max-width: 767px) {
      .main-visual .gray3 {
        width: 25px;
        height: 25px;
        border: 2px solid #e9eef3;
        background-color: transparent;
        bottom: 5%;
        left: 29%; } }
  .main-visual .pink1 {
    width: 9.15081vw;
    height: 9.15081vw;
    background-color: #d6258b;
    left: 9.52%;
    top: 40%; }
    @media screen and (max-width: 1440px) {
      .main-visual .pink1 {
        top: 34%; } }
    @media screen and (max-width: 1024px) {
      .main-visual .pink1 {
        width: 85px;
        height: 85px;
        background: transparent;
        border: 4px solid #d6258b;
        top: 14%;
        left: 14%; } }
    @media screen and (max-width: 767px) {
      .main-visual .pink1 {
        width: 42.5px;
        height: 42.5px;
        background: transparent;
        border: 2px solid #d6258b;
        top: 17%;
        left: 20%; } }
  .main-visual .pink2 {
    width: 8.66667vw;
    height: 8.66667vw;
    background: #d6258b;
    bottom: 25%;
    left: -2%; }
    @media screen and (max-width: 767px) {
      .main-visual .pink2 {
        width: 32.5px;
        height: 32.5px;
        background: #d6258b;
        bottom: 22%;
        left: -2%; } }
  .main-visual .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    overflow: hidden; }
  .main-visual .img1 {
    width: 19.76574vw;
    height: 19.76574vw;
    max-width: 270px;
    max-height: 270px;
    bottom: 13%;
    left: 20%; }
    @media screen and (max-width: 1024px) {
      .main-visual .img1 {
        width: 36vw;
        height: 36vw;
        left: auto;
        right: -10%;
        bottom: 0; } }
    @media screen and (max-width: 767px) {
      .main-visual .img1 {
        width: 135px;
        height: 135px;
        left: auto;
        right: -10%;
        bottom: 0; } }
    .main-visual .img1 img {
      height: 600px;
      position: absolute;
      bottom: 0;
      left: -70%; }
      @media screen and (max-width: 1024px) {
        .main-visual .img1 img {
          height: 170%;
          left: 75%;
          -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
                  transform: translateX(-50%); } }
      @media screen and (max-width: 767px) {
        .main-visual .img1 img {
          height: 170%;
          left: 75%;
          -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
                  transform: translateX(-50%); } }
  .main-visual .img2 {
    width: 9.88287vw;
    height: 9.88287vw;
    max-width: 135px;
    max-height: 135px;
    bottom: 5%;
    left: 10%; }
    @media screen and (max-width: 1024px) {
      .main-visual .img2 {
        width: 150px;
        height: 150px;
        bottom: auto;
        top: 40%;
        left: -4%; } }
    @media screen and (max-width: 767px) {
      .main-visual .img2 {
        width: 75px;
        height: 75px;
        bottom: auto;
        top: 45%;
        left: -5%; } }
    .main-visual .img2 img {
      position: absolute;
      left: 50%;
      top: 0;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media screen and (max-width: 767px) {
        .main-visual .img2 img {
          height: 100%; } }
  .main-visual .img3 {
    width: 48.68228vw;
    height: 48.68228vw;
    max-width: 665px;
    max-height: 665px;
    right: 10%;
    top: 4%; }
    @media screen and (max-width: 1024px) {
      .main-visual .img3 {
        width: 74.66667vw;
        height: 74.66667vw;
        max-width: 560px;
        max-height: 560px;
        top: 36%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        right: -8%;
        z-index: 1; } }
    @media screen and (max-width: 767px) {
      .main-visual .img3 {
        width: 280px;
        height: 280px;
        top: 35%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        right: -10%;
        z-index: 1; } }
    .main-visual .img3 img {
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 100%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
  .main-visual .img4 {
    width: 20.4978vw;
    height: 20.4978vw;
    max-width: 280px;
    max-height: 280px;
    right: -3%;
    top: 0; }
    @media screen and (max-width: 1024px) {
      .main-visual .img4 {
        width: 37.33333vw;
        height: 37.33333vw;
        right: auto;
        left: 20%;
        bottom: 13%;
        top: auto; } }
    @media screen and (max-width: 767px) {
      .main-visual .img4 {
        width: 140px;
        height: 140px;
        right: auto;
        left: 20%;
        bottom: 13%;
        top: auto; } }
    .main-visual .img4 img {
      position: absolute;
      top: 0;
      left: 43%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      height: 102%; }
      @media screen and (max-width: 767px) {
        .main-visual .img4 img {
          height: 120%; } }
  .main-visual .img5 {
    width: 12.07906vw;
    height: 12.07906vw;
    max-width: 165px;
    max-height: 165px;
    top: 50%;
    right: -3%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media screen and (max-width: 1024px) {
      .main-visual .img5 {
        width: 22vw;
        height: 22vw;
        top: auto;
        bottom: -9%;
        right: 15%;
        z-index: -1; } }
    @media screen and (max-width: 767px) {
      .main-visual .img5 {
        width: 82.5px;
        height: 82.5px;
        top: auto;
        bottom: -9%;
        right: 22%; } }
    .main-visual .img5 img {
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media screen and (max-width: 1024px) {
        .main-visual .img5 img {
          -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
                  transform: translateX(-50%);
          height: 100%; } }
      @media screen and (max-width: 767px) {
        .main-visual .img5 img {
          -webkit-transform: translateX(-70%);
              -ms-transform: translateX(-70%);
                  transform: translateX(-70%);
          width: 200%; } }
  .main-visual .subcirc {
    position: absolute;
    border-radius: 50%; }
  .main-visual .subcirc1 {
    width: 80px;
    height: 80px;
    border: 2px solid #d6258b;
    bottom: 6%;
    right: -5%; }
    @media screen and (max-width: 1024px) {
      .main-visual .subcirc1 {
        width: 14.66667vw;
        height: 14.66667vw;
        max-width: 110px;
        max-height: 110px;
        border: 0;
        background-color: #d6258b;
        bottom: auto;
        top: -50%;
        right: 20%;
        z-index: 2; } }
    @media screen and (max-width: 767px) {
      .main-visual .subcirc1 {
        width: 55px;
        height: 55px;
        border: 0;
        background-color: #d6258b;
        bottom: auto;
        top: -50%;
        right: 20%;
        z-index: 2; } }
  .main-visual .subcirc2 {
    width: 30px;
    height: 30px;
    border: 2px solid #d6258b;
    bottom: 0;
    right: -14%; }
  .main-visual .subcirc3 {
    width: 85px;
    height: 85px;
    background-color: #d6258b;
    bottom: -35%;
    left: -8%; }
    @media screen and (max-width: 1024px) {
      .main-visual .subcirc3 {
        bottom: auto;
        top: -25%;
        left: 10%;
        width: 10.66667vw;
        height: 10.66667vw;
        max-width: 80px;
        max-height: 80px;
        background-color: transparent;
        border: 4px solid #d6258b; } }
    @media screen and (max-width: 767px) {
      .main-visual .subcirc3 {
        display: none; } }
  .main-visual .subcirc4 {
    width: 30px;
    height: 30px;
    background: transparent;
    border: 2px solid #e9eef3;
    bottom: -60%;
    left: -15%; }
    @media screen and (max-width: 1024px) {
      .main-visual .subcirc4 {
        display: none; } }
    @media screen and (max-width: 767px) {
      .main-visual .subcirc4 {
        display: block;
        border-color: #d6258b;
        width: 40px;
        height: 40px;
        top: -32%;
        left: 10%; } }
  .main-visual__tracks {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    bottom: 25px;
    left: 0;
    z-index: 4; }
    @media screen and (max-width: 767px) {
      .main-visual__tracks {
        bottom: 5%; } }
  .main-visual__track {
    width: 50px;
    height: 4px;
    background-color: #e9eef3;
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }
    @media screen and (max-width: 767px) {
      .main-visual__track {
        width: 30px; } }
    .main-visual__track:first-of-type {
      margin-right: 10px; }
      @media screen and (max-width: 767px) {
        .main-visual__track:first-of-type {
          margin-right: 15px; } }
    .main-visual__track.active {
      background-color: #d6258b; }

.support {
  padding: 90px 0 100px;
  text-align: center;
  background-image: url("/src/img/support-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media screen and (max-width: 767px) {
    .support {
      padding: 50px 5%;
      overflow: hidden; } }
  .support__title {
    font-size: 3.6rem;
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.38889em;
    color: #333;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .support__title {
        font-size: 25px;
        line-height: 1.44em;
        margin-bottom: 20px; } }
  .support__text {
    font-size: 1.6rem;
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.75em;
    color: #333; }
    @media screen and (max-width: 767px) {
      .support__text {
        font-size: 15px;
        line-height: 1.6em;
        text-align: left; } }

.strengths {
  margin-top: 90px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .strengths {
      margin-top: 45px; } }
  @media screen and (max-width: 767px) {
    .strengths__content {
      overflow: hidden; } }
  .strengths__list {
    margin-top: 20px; }
  .strengths__item-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1070px;
    margin: auto; }
    @media screen and (max-width: 1024px) {
      .strengths__item-wrapper {
        padding: 0 5%; } }
    @media screen and (max-width: 767px) {
      .strengths__item-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .strengths__item-image {
    width: 320px;
    height: 320px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin-right: 20px;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
    @media screen and (max-width: 767px) {
      .strengths__item-image {
        margin: 0 auto 10px;
        width: 160px;
        height: 160px;
        max-width: 320px;
        max-height: 320px; } }
  .strengths__item-title {
    font-size: 3.6rem;
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    letter-spacing: 0;
    line-height: 1.38889em;
    font-weight: 500; }
    @media screen and (max-width: 767px) {
      .strengths__item-title {
        font-size: 25px;
        line-height: 1.36em;
        margin-bottom: 5px;
        text-align: center; } }
  .strengths__item-desc {
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.75em;
    margin-top: 25px; }
    @media screen and (max-width: 767px) {
      .strengths__item-desc {
        font-size: 15px;
        line-height: 1.6em;
        margin-top: 0; } }
  .strengths__item {
    position: relative;
    color: #333; }
    .strengths__item::before {
      content: '';
      position: absolute;
      left: 0;
      top: 60px;
      width: 100%;
      height: 1px;
      background: #e9eef3; }
    .strengths__item:not(:first-of-type) {
      margin-top: -25px; }
      @media screen and (max-width: 767px) {
        .strengths__item:not(:first-of-type) {
          margin-top: 20px; } }
    .strengths__item:nth-of-type(even) .strengths__item-wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; }
      @media screen and (max-width: 767px) {
        .strengths__item:nth-of-type(even) .strengths__item-wrapper {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
              -ms-flex-direction: column;
                  flex-direction: column; } }
    .strengths__item:nth-of-type(even) .strengths__item-image {
      margin-right: 0;
      margin-left: 30px; }
      @media screen and (max-width: 767px) {
        .strengths__item:nth-of-type(even) .strengths__item-image {
          margin: 0 auto 10px; } }
    .strengths__item:first-of-type .strengths__item-image img {
      position: absolute;
      right: -21%;
      top: -3%;
      height: 106%; }
      @media screen and (max-width: 767px) {
        .strengths__item:first-of-type .strengths__item-image img {
          height: 110%; } }
    .strengths__item:nth-of-type(2) .strengths__item-image img {
      position: relative;
      right: 70px; }
      @media screen and (max-width: 767px) {
        .strengths__item:nth-of-type(2) .strengths__item-image img {
          height: 100%;
          right: auto;
          left: 55%;
          -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
                  transform: translateX(-50%); } }
    .strengths__item:nth-of-type(3) .strengths__item-image img {
      position: absolute;
      left: 48%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media screen and (max-width: 767px) {
        .strengths__item:nth-of-type(3) .strengths__item-image img {
          height: 115%; } }
    .strengths__item:nth-of-type(4) .strengths__item-image img {
      width: 490px;
      position: relative;
      right: 50px; }
      @media screen and (max-width: 767px) {
        .strengths__item:nth-of-type(4) .strengths__item-image img {
          width: auto;
          height: 100%;
          right: auto;
          left: -10%; } }
    .strengths__item:last-of-type .strengths__item-image img {
      width: 500px;
      position: absolute;
      top: 0;
      left: 67%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media screen and (max-width: 767px) {
        .strengths__item:last-of-type .strengths__item-image img {
          width: auto;
          height: 100%; } }

.business {
  margin-top: 70px;
  padding-top: 50px;
  background: #f6f6f0; }
  @media screen and (max-width: 767px) {
    .business {
      overflow: hidden; } }
  .business__wrapper {
    margin-top: 10px;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .business__wrapper {
        max-width: 750px;
        margin: auto;
        padding: 0 3%; } }
    @media screen and (max-width: 767px) {
      .business__wrapper {
        max-width: 375px;
        margin: 10px auto 0; } }
  .business__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px; }
    @media screen and (max-width: 1024px) {
      .business__row {
        margin-top: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .business__bg {
    top: 0;
    width: 100%;
    height: 100%; }
    .business__bg img {
      width: 870px;
      position: absolute;
      right: 0; }
      @media screen and (max-width: 1024px) {
        .business__bg img {
          width: 50%;
          bottom: -40px; } }
      @media screen and (max-width: 767px) {
        .business__bg img {
          width: 70%;
          right: -12%;
          bottom: -20px; } }
  .business__content {
    max-width: 980px;
    margin: 0 auto;
    position: relative;
    z-index: 1; }
  .business__text {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .business__text {
        font-size: 15px;
        padding: 0 5%; } }
  .business__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 66%;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; }
    @media screen and (max-width: 1024px) {
      .business__list {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .business__list {
        margin-top: 40px; } }
  .business__list-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .business__list-row:nth-of-type(2) {
      margin-left: 90px; }
  .business__item {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 2px solid #d6258b;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 1.9rem;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1.36842em;
    text-align: center;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .business__item {
        width: 245px;
        height: 245px;
        font-size: 30px; } }
    @media screen and (max-width: 767px) {
      .business__item {
        width: 122px;
        height: 122px;
        line-height: 1.4em;
        font-size: 15px; } }
    @media screen and (max-width: 320px) {
      .business__item {
        width: 107.8px;
        height: 107.8px;
        font-size: 12px; } }
    .business__item:not(:last-of-type) {
      margin-right: 20px; }
      @media screen and (max-width: 767px) {
        .business__item:not(:last-of-type) {
          margin-right: 10px; } }
    .business__item:nth-of-type(4) {
      margin-left: 10%; }
      @media screen and (max-width: 1024px) {
        .business__item:nth-of-type(4) {
          margin-left: 0; } }
    @media screen and (max-width: 1024px) {
      .business__item:nth-of-type(3), .business__item:nth-of-type(7) {
        margin-left: 20%; } }
    @media screen and (max-width: 320px) {
      .business__item:nth-of-type(3), .business__item:nth-of-type(7) {
        margin-left: 15%; } }
  @media screen and (max-width: 1024px) {
    .business__image {
      margin-top: -235px;
      position: relative;
      z-index: -1; } }
  @media screen and (max-width: 767px) {
    .business__image {
      margin-top: -125px; } }
  .business__image img {
    margin-left: -30%;
    margin-bottom: -7%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    @media screen and (max-width: 1024px) {
      .business__image img {
        margin-left: 8%;
        margin-bottom: 0;
        height: 500px; } }
    @media screen and (max-width: 767px) {
      .business__image img {
        margin-left: 8%;
        height: 250px; } }

.cases {
  padding: 100px 0 70px; }
  @media screen and (max-width: 767px) {
    .cases {
      overflow: hidden;
      padding: 50px 0 35px; } }
  .cases__content {
    max-width: 980px;
    margin: 30px auto 0; }
    @media screen and (max-width: 1024px) {
      .cases__content {
        padding: 0 5%; } }
    @media screen and (max-width: 767px) {
      .cases__content {
        margin-top: 70px; } }
  .cases__item {
    padding: 25px 0;
    border-top: 1px solid #ccc; }
    @media screen and (max-width: 767px) {
      .cases__item {
        padding: 0 0 20px;
        border: 1.5px solid #ccc; } }
    .cases__item:not(:last-of-type) {
      margin-bottom: 55px;
      border-bottom: 1px solid #ccc; }
      @media screen and (max-width: 767px) {
        .cases__item:not(:last-of-type) {
          margin-bottom: 32.5px; } }
  .cases__title-container, .cases__number {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .cases__title-container {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }
    @media screen and (max-width: 767px) {
      .cases__title-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .cases__number {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    color: #fff;
    width: 140px;
    height: 140px;
    background-color: #d6258b;
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    margin-right: 30px; }
    @media screen and (max-width: 767px) {
      .cases__number {
        width: 80px;
        height: 80px;
        margin: -25px auto 15px; } }
    .cases__number * {
      line-height: 1;
      font-weight: 500;
      letter-spacing: 0; }
    .cases__number p {
      font-size: 3rem; }
      @media screen and (max-width: 767px) {
        .cases__number p {
          font-size: 17px; } }
    .cases__number strong {
      font-size: 6rem; }
      @media screen and (max-width: 767px) {
        .cases__number strong {
          font-size: 34px; } }
  .cases__title {
    color: #d6258b;
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 1.35714em;
    letter-spacing: 0; }
    @media screen and (max-width: 767px) {
      .cases__title {
        font-size: 16px;
        line-height: 1.58824em; } }
    @media screen and (max-width: 767px) {
      .cases__title ul {
        padding: 0 4%; } }
    .cases__title li {
      border: 2px solid #d6258b;
      padding: 25px;
      background-color: #fff;
      border-radius: 15px;
      position: relative; }
      .cases__title li::before {
        content: '';
        position: absolute;
        left: 20px;
        bottom: -10px;
        width: 18px;
        height: 17px;
        background-color: #fff;
        border: 2px solid #d6258b;
        border-left: 0;
        border-bottom: 0;
        -webkit-transform: skewX(-50deg) rotateZ(135deg);
            -ms-transform: skewX(-50deg) rotate(135deg);
                transform: skewX(-50deg) rotateZ(135deg); }
        @media screen and (max-width: 767px) {
          .cases__title li::before {
            width: 13px;
            height: 12px;
            bottom: -8px; } }
      .cases__title li:not(:last-of-type) {
        margin-bottom: 20px; }
  .cases__desc {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.77778em;
    letter-spacing: 0;
    margin-top: 15px; }
    @media screen and (max-width: 767px) {
      .cases__desc {
        padding: 0 6%;
        font-size: 15px;
        line-height: 1.6em;
        margin-top: 15px; } }
  .cases__link {
    margin-top: 25px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .cases__link a {
      width: 300px;
      height: 50px;
      border-radius: 5px;
      color: #fff;
      background: -webkit-gradient(linear, left bottom, left top, from(#9c1f67), color-stop(40%, #d6258b), to(#d6258b));
      background: -o-linear-gradient(bottom, #9c1f67 0%, #d6258b 40%, #d6258b 100%);
      background: linear-gradient(0deg, #9c1f67 0%, #d6258b 40%, #d6258b 100%);
      font-size: 2rem;
      letter-spacing: 0;
      font-weight: 500;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      position: relative; }
      @media screen and (max-width: 767px) {
        .cases__link a {
          font-size: 15px;
          width: 66%;
          height: 40px; } }
      .cases__link a::after {
        content: '';
        position: absolute;
        right: 20px;
        top: 50%;
        width: 10px;
        height: 10px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: translateY(-50%) rotate(45deg);
            -ms-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg); }
        @media screen and (max-width: 767px) {
          .cases__link a::after {
            width: 7px;
            height: 7px;
            right: 15px; } }

@media screen and (max-width: 767px) {
  .cost {
    overflow: hidden; } }

.cost__content {
  max-width: 980px;
  margin: 15px auto 0; }
  @media screen and (max-width: 1024px) {
    .cost__content {
      padding: 0 5%; } }

.cost__title {
  font-size: 2.4rem;
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0;
  margin-bottom: 5px; }
  @media screen and (max-width: 767px) {
    .cost__title {
      font-size: 20px;
      line-height: 1.1em;
      margin-bottom: 10px; } }

.cost__table-container {
  overflow: auto; }
  .cost__table-container.second {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border-bottom: 2px solid #d6258b; }
  .cost__table-container.toggled {
    padding-bottom: 25px;
    max-height: 1500px; }
    @media screen and (max-width: 767px) {
      .cost__table-container.toggled {
        max-height: 2000px;
        padding-bottom: 15px; } }
  .cost__table-container:first-of-type {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .cost__table-container:first-of-type {
        margin-bottom: 15px; } }

.cost__table-wrapper {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border-bottom: 2px solid #d6258b; }
  .cost__table-wrapper.toggled {
    padding-bottom: 25px;
    max-height: 1500px; }
    @media screen and (max-width: 767px) {
      .cost__table-wrapper.toggled {
        max-height: 2000px;
        padding-bottom: 15px; } }

.cost__table-toggle {
  font-size: 1.8rem;
  font-weight: 500;
  color: #d6258b;
  letter-spacing: 0;
  line-height: 1.55556em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto 30px;
  width: 300px;
  height: 50px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left bottom, left top, from(#9c1f67), color-stop(40%, #d6258b), to(#d6258b));
  background: -o-linear-gradient(bottom, #9c1f67 0%, #d6258b 40%, #d6258b 100%);
  background: linear-gradient(0deg, #9c1f67 0%, #d6258b 40%, #d6258b 100%);
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .cost__table-toggle {
      width: 68%;
      height: 40px;
      padding: 0 3%;
      font-size: 1.5rem;
      line-height: 1.6em;
      margin-bottom: 15px; } }
  .cost__table-toggle * {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    z-index: 1;
    color: #fff; }

.cost__table-toggle-lines {
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 20px; }
  .cost__table-toggle-lines::before, .cost__table-toggle-lines::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #fff;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; }
  .cost__table-toggle-lines::after {
    left: 50%;
    top: 0;
    width: 2px;
    height: 100%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%); }
  .cost__table-toggle-lines.toggled::after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
        -ms-transform: translate(-50%, -50%) rotate(90deg);
            transform: translate(-50%, -50%) rotate(90deg); }

.cost__table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #cbd1d6; }
  @media screen and (max-width: 1200px) {
    .cost__table {
      table-layout: fixed; } }
  .cost__table th,
  .cost__table td {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.75em;
    letter-spacing: 0;
    padding: 10px 0; }
    @media screen and (max-width: 767px) {
      .cost__table th,
      .cost__table td {
        font-size: 15px;
        line-height: 1.33333em; } }
  .cost__table th {
    text-align: center;
    background-color: #f4e9ef; }
    .cost__table th:not(:first-of-type) {
      border-left: 1px solid #cbd1d6; }
    .cost__table th:nth-of-type(2) {
      width: 20.4%; }
      @media screen and (max-width: 1200px) {
        .cost__table th:nth-of-type(2) {
          width: auto; } }
  .cost__table td {
    border: 1px solid #cbd1d6;
    padding: 10px 15px; }
    @media screen and (max-width: 767px) {
      .cost__table td {
        line-height: 1.4em;
        padding: 10px; } }
    .cost__table td:not(:first-of-type) {
      text-align: right; }
      @media screen and (max-width: 767px) {
        .cost__table td:not(:first-of-type) {
          font-size: 14px; } }
  .cost__table .yellow td {
    background-color: #fff2e2; }

.cost__scroll-indicator {
  display: none;
  position: relative; }
  @media screen and (max-width: 767px) {
    .cost__scroll-indicator {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 15px;
      font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
      font-weight: 500;
      letter-spacing: 0;
      margin-top: 10px;
      color: #aaa; } }
  .cost__scroll-indicator span {
    width: 45px;
    height: 1px;
    background-color: #aaa;
    position: relative;
    margin-left: 10px;
    -webkit-animation: slideRight 1.4s ease infinite;
            animation: slideRight 1.4s ease infinite; }
    .cost__scroll-indicator span::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 7px;
      height: 7px;
      background-color: #aaa; }

.cost__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75em;
  letter-spacing: 0;
  margin-top: 15px; }
  @media screen and (max-width: 767px) {
    .cost__text {
      font-size: 1.5rem;
      line-height: 1.5em; } }
  .cost__text.ptb-30 {
    border-top: 1px solid #cbd1d6; }
  .cost__text b {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.55556em; }
    @media screen and (max-width: 767px) {
      .cost__text b {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.5em; } }

@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px); } }

@keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px); } }

.greeting {
  width: 100%;
  margin-top: 125px; }
  @media screen and (max-width: 767px) {
    .greeting {
      margin-top: 50px; } }
  .greeting__content {
    max-width: 980px;
    margin: 20px auto 0; }
    @media screen and (max-width: 1024px) {
      .greeting__content {
        padding: 0 5%; } }
    @media screen and (max-width: 767px) {
      .greeting__content {
        margin: 10px auto 0; } }
  .greeting__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media screen and (max-width: 767px) {
      .greeting__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .greeting__item:last-of-type {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      margin-top: 100px; }
      @media screen and (max-width: 767px) {
        .greeting__item:last-of-type {
          margin-top: 50px;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
              -ms-flex-direction: column;
                  flex-direction: column; } }
  .greeting__image-container, .greeting__details {
    width: 50%; }
    @media screen and (max-width: 767px) {
      .greeting__image-container, .greeting__details {
        width: 100%; } }
  .greeting__image-container {
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    position: relative; }
    @media screen and (max-width: 767px) {
      .greeting__image-container {
        max-width: 335px;
        margin: auto; } }
  .greeting__image {
    border-radius: 50%;
    position: relative;
    overflow: hidden; }
  .greeting .first {
    width: 470px;
    height: 470px;
    margin-left: -40px; }
    @media screen and (max-width: 767px) {
      .greeting .first {
        width: 237.5px;
        height: 237.5px;
        margin: auto; } }
    .greeting .first img {
      height: 100%;
      position: absolute;
      top: 0;
      left: 45%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media screen and (max-width: 767px) {
        .greeting .first img {
          width: auto;
          height: 100%; } }
  .greeting .second {
    width: 370px;
    height: 370px;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .greeting .second {
        width: 237.5px;
        height: 237.5px; } }
    .greeting .second img {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media screen and (max-width: 767px) {
        .greeting .second img {
          width: auto;
          height: 100%; } }
  .greeting .circ {
    position: absolute;
    border-radius: 50%; }
  .greeting .pink1 {
    width: 40px;
    height: 40px;
    background-color: #d6258b;
    top: -9%;
    left: -12%; }
    @media screen and (max-width: 767px) {
      .greeting .pink1 {
        left: 8%;
        width: 20px;
        height: 20px; } }
    .greeting .pink1.border {
      background-color: transparent;
      border: 2px solid #d6258b;
      bottom: -3%;
      right: 15%;
      left: auto;
      top: auto;
      width: 130px;
      height: 130px;
      z-index: -1; }
      @media screen and (max-width: 767px) {
        .greeting .pink1.border {
          width: 65px;
          height: 65px; } }
  .greeting .gray1 {
    background-color: #e9eef3; }
    .greeting .gray1.bigger {
      width: 110px;
      height: 110px;
      top: -3%;
      left: -5%; }
      @media screen and (max-width: 767px) {
        .greeting .gray1.bigger {
          width: 55px;
          height: 55px;
          top: 0;
          left: 15%; } }
    .greeting .gray1.smaller {
      bottom: -5%;
      right: 0;
      width: 50px;
      height: 50px; }
      @media screen and (max-width: 767px) {
        .greeting .gray1.smaller {
          bottom: 0;
          right: 3%;
          width: 25px;
          height: 25px; } }
  .greeting .pink2 {
    width: 20px;
    height: 20px;
    background-color: #d6258b;
    top: -10%; }
    @media screen and (max-width: 767px) {
      .greeting .pink2 {
        top: -3%;
        left: 4%; } }
    .greeting .pink2.border {
      width: 125px;
      height: 125px;
      background-color: transparent;
      border: 2px solid #d6258b;
      left: 5%; }
      @media screen and (max-width: 767px) {
        .greeting .pink2.border {
          width: 80px;
          height: 80px;
          top: 0;
          left: 10%; } }
  .greeting .gray2 {
    background-color: #e9eef3;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 0; }
    @media screen and (max-width: 767px) {
      .greeting .gray2 {
        width: 20px;
        height: 20px; } }
  .greeting__details {
    font-size: 1.6rem;
    line-height: 1.75em;
    letter-spacing: 0; }
    @media screen and (max-width: 767px) {
      .greeting__details {
        margin-top: 20px;
        font-size: 15px;
        line-height: 1.6em; } }
    .greeting__details ul {
      list-style-type: disc; }
    .greeting__details li {
      margin-left: 20px; }
    .greeting__details--yumin {
      font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
      font-weight: 500; }
      .greeting__details--yumin * {
        font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
        font-weight: 500; }
  .greeting__title {
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    font-size: 2.4rem;
    line-height: 1.58333em;
    font-weight: 500;
    margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .greeting__title {
        font-size: 20px;
        margin-bottom: 10px; } }
    .greeting__title.sm {
      font-size: 2rem;
      line-height: 1.4em;
      margin-bottom: 0; }
  .greeting .ta-right {
    text-align: right;
    font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "游明朝", "游明朝体", YuMincho, "Yu Mincho", "ＭＳ 明朝", "MS Mincho", HiraMinProN-W3, "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
    font-weight: 500; }
    .greeting .ta-right span {
      font-size: 2.4rem;
      line-height: 1; }
      @media screen and (max-width: 767px) {
        .greeting .ta-right span {
          font-size: 20px; } }
  .greeting .mb-30 {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .greeting .mb-30 {
        margin-bottom: 15px; } }

.office {
  margin-top: 115px; }
  @media screen and (max-width: 767px) {
    .office {
      overflow: hidden; } }
  @media screen and (max-width: 767px) and (max-width: 767px) {
    .office {
      margin-top: 50px; } }
  .office__content {
    max-width: 980px;
    margin: 40px auto 0; }
    @media screen and (max-width: 1024px) {
      .office__content {
        padding: 0 5%; } }
    @media screen and (max-width: 767px) {
      .office__content {
        margin-top: 20px; } }
  .office__map-container {
    height: 250px;
    width: 100%;
    overflow: hidden;
    border: 1px solid #cbd1d6;
    margin-top: 30px; }
  .office__text {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75em;
    margin-top: 15px; }
    @media screen and (max-width: 767px) {
      .office__text {
        font-size: 15px;
        line-height: 1.6em; } }
  .office__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media screen and (max-width: 767px) {
      .office__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .office__row:not(:last-of-type) {
      margin-bottom: 20px; }
    .office__row .jp {
      font-size: 1.8rem; }
      @media screen and (max-width: 767px) {
        .office__row .jp {
          font-size: 15px; } }
    .office__row .en {
      font-size: 1.4rem; }
      @media screen and (max-width: 767px) {
        .office__row .en {
          font-size: 10px; } }
  .office__label {
    width: 12.76%; }
    @media screen and (max-width: 767px) {
      .office__label {
        width: 100%; } }
  .office__desc {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
    @media screen and (max-width: 767px) {
      .office__desc {
        padding-left: 10%;
        margin-top: 10px; } }

@media screen and (max-width: 767px) {
  .contact {
    overflow: hidden; } }

.contact__content {
  max-width: 980px;
  margin: 75px auto 0;
  background-color: #f2f5f7;
  padding: 65px 0 75px; }
  @media screen and (max-width: 767px) {
    .contact__content {
      margin-top: 45px;
      padding: 40px 5% 55px; } }

.contact__form {
  margin: 45px auto 0;
  width: 71%; }
  @media screen and (max-width: 1024px) {
    .contact__form {
      width: 80%; } }
  @media screen and (max-width: 767px) {
    .contact__form {
      width: 100%;
      margin: 20px auto 0; } }

.contact__form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .contact__form-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  .contact__form-row:not(:last-of-type) {
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .contact__form-row:not(:last-of-type) {
        margin-bottom: 15px; } }
  .contact__form-row.ai-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .contact__form-row.mb-10 {
    margin-bottom: 10px; }

.contact__form-label {
  width: 29%;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 1.75em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .contact__form-label {
      width: 100%;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      font-size: 15px; } }
  .contact__form-label .en {
    font-size: 1.4rem;
    line-height: 1; }
    @media screen and (max-width: 767px) {
      .contact__form-label .en {
        font-size: 10px; } }

.contact__form-input-container {
  width: 69%;
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.75em; }
  @media screen and (max-width: 767px) {
    .contact__form-input-container {
      width: 100%;
      font-size: 15px; } }
  .contact__form-input-container input,
  .contact__form-input-container textarea {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #85898e;
    padding: 10px;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: noto-sans-cjk-jp, sans-serif;
    margin-left: auto; }
    @media screen and (max-width: 767px) {
      .contact__form-input-container input,
      .contact__form-input-container textarea {
        font-size: 15px;
        margin-top: 5px; } }
    .contact__form-input-container input::-webkit-input-placeholder,
    .contact__form-input-container textarea::-webkit-input-placeholder {
      color: #cbd1d6; }
    .contact__form-input-container input:-ms-input-placeholder,
    .contact__form-input-container textarea:-ms-input-placeholder {
      color: #cbd1d6; }
    .contact__form-input-container input::-ms-input-placeholder,
    .contact__form-input-container textarea::-ms-input-placeholder {
      color: #cbd1d6; }
    .contact__form-input-container input::placeholder,
    .contact__form-input-container textarea::placeholder {
      color: #cbd1d6; }
  .contact__form-input-container textarea {
    height: 180px; }
  .contact__form-input-container.zip {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .contact__form-input-container.radio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }

.contact .contact__form-input-group {
  width: 33.33%;
  padding: 10px;
  border: 1px solid #85898e;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 5px;
  margin-right: 10px; }
  @media screen and (max-width: 767px) {
    .contact .contact__form-input-group {
      padding: 5px;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; } }
  .contact .contact__form-input-group input {
    height: 100%;
    border: 0;
    outline: 0; }
    @media screen and (max-width: 767px) {
      .contact .contact__form-input-group input {
        width: 100%;
        padding: 0;
        padding-left: 5px;
        margin-top: 0; } }
  .contact .contact__form-input-group span {
    line-height: 1; }

.contact__form-zip-button {
  background: transparent;
  color: #d6258b;
  text-decoration: underline;
  border: 0;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  padding-bottom: 5px;
  text-underline-offset: 0.3em; }

.contact__form-select {
  width: 33.33%;
  height: 40px;
  background-color: #fff;
  border: 1px solid #85898e;
  border-radius: 5px;
  margin: auto;
  position: relative; }
  .contact__form-select::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
        -ms-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
    width: 9px;
    height: 9px;
    border-right: 2px solid #d6258b;
    border-bottom: 2px solid #d6258b; }
  .contact__form-select select {
    width: 100%;
    height: 100%;
    border: 0;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(30%, rgba(84, 85, 87, 0.146096)), to(rgba(238, 238, 238, 0)));
    background: -o-linear-gradient(bottom, rgba(84, 85, 87, 0.146096) 30%, rgba(238, 238, 238, 0) 100%);
    background: linear-gradient(0deg, rgba(84, 85, 87, 0.146096) 30%, rgba(238, 238, 238, 0) 100%);
    font-size: 1.6rem;
    font-weight: 400;
    font-family: noto-sans-cjk-jp, sans-serif;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding: 5px 10px;
    outline: 0; }

.contact__form-radio-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #595959;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
  .contact__form-radio-circle::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    background-color: #d6258b;
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease; }

.contact__form-radio {
  padding-left: 23px;
  position: relative; }
  .contact__form-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0; }
    .contact__form-radio input[type="radio"]:checked ~ .contact__form-radio-circle::after {
      opacity: 1; }

.contact__require {
  color: #d6258b;
  font-size: 1.4rem;
  line-height: 1;
  padding-top: 15px; }
  @media screen and (max-width: 767px) {
    .contact__require {
      margin-left: 10px;
      padding-top: 0;
      line-height: initial; } }

.contact__form-message {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.75em;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #85898e;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .contact__form-message {
      font-size: 15px;
      line-height: 1.6em; } }

.contact__form-message-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto; }
  .contact__form-message-wrapper::-webkit-scrollbar {
    width: 10px; }
  .contact__form-message-wrapper::-webkit-scrollbar-track {
    background-color: #cbd1d6;
    border-radius: 5px; }
  .contact__form-message-wrapper::-webkit-scrollbar-thumb {
    background-color: #85898e;
    border-radius: 5px; }

.contact__form-agree {
  text-align: center;
  margin-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .contact__form-agree {
      margin-bottom: 20px; } }
  .contact__form-agree label {
    display: inline-block;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 1.75em;
    position: relative;
    padding-left: 30px; }
    @media screen and (max-width: 767px) {
      .contact__form-agree label {
        font-size: 15px; } }
  .contact__form-agree span {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 25px;
    height: 25px;
    background-color: #fff;
    border: 1px solid #85898e;
    border-radius: 3px; }
    .contact__form-agree span::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 40%;
      width: 40%;
      height: 70%;
      border-right: 3px solid #d6258b;
      border-bottom: 3px solid #d6258b;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
          -ms-transform: translate(-50%, -50%) rotate(45deg);
              transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease; }
  .contact__form-agree input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .contact__form-agree input:checked ~ span::after {
      opacity: 1; }

.contact__form-submit button,
.contact__form-submit input {
  width: 43.17%;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #d6258b;
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4em;
  border: 0;
  margin: auto;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease; }
  @media screen and (max-width: 767px) {
    .contact__form-submit button,
    .contact__form-submit input {
      width: 71%; } }
  .contact__form-submit button::after,
  .contact__form-submit input::after {
    content: '';
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-45deg);
        -ms-transform: translateY(-50%) rotate(-45deg);
            transform: translateY(-50%) rotate(-45deg);
    width: 10px;
    height: 10px;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff; }
    @media screen and (max-width: 767px) {
      .contact__form-submit button::after,
      .contact__form-submit input::after {
        right: 10px; } }
  .contact__form-submit button:hover,
  .contact__form-submit input:hover {
    opacity: 0.7; }

.contact__form-return {
  width: 43.17%;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  color: #d6258b;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4em;
  border: 1px solid #d6258b;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease; }
  .contact__form-return:hover {
    opacity: 0.7;
    background: #d6258b;
    color: #fff; }

.contact__form-button-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
  .contact__form-button-row .contact__form-submit {
    width: 43%; }
    .contact__form-button-row .contact__form-submit button {
      width: 100%; }

.contact__complete-message {
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75em;
  letter-spacing: 0;
  margin-top: 30px; }

.contact__text {
  font-size: 1.4rem;
  line-height: 1.71429em;
  margin-bottom: 20px; }

.contact .js-btn-confirm:disabled {
  cursor: auto; }

.white {
  color: #fff; }

.white-bg {
  background: #fff; }

.black {
  color: #000; }

.black-bg {
  background: #000; }

.primary {
  color: #d6258b; }

.primary-bg {
  background: #d6258b; }

.ml-10 {
  margin-left: 10px; }

.mt-10 {
  margin-top: 10px; }

.tablet-show {
  display: none; }
  @media screen and (max-width: 1024px) {
    .tablet-show {
      display: block; } }

.tablet-hide {
  display: block; }
  @media screen and (max-width: 1024px) {
    .tablet-hide {
      display: none; } }

.ptb-30 {
  padding: 30px 0; }
  @media screen and (max-width: 767px) {
    .ptb-30 {
      padding: 15px 0; } }

.t-center {
  text-align: center; }
  @media screen and (max-width: 767px) {
    .t-center {
      text-align: left; } }
